<template>
  <div class="tw-p-6 md:tw-p-10 tw-drop-shadow-md tw-border-b-2">
    <div class="tw-flex tw-items-center tw-justify-between tw-mb-6">
      <router-link
        :to="{ name: 'salon-services' }"
        class="text-lg tw-font-semibold tw-text-blue-700 tw-hover:tx-text-blue-900">
        <i class="mr-2 pi pi-arrow-left"></i>
        Kembali
      </router-link>
      <Button label="Edit Layanan" 
        icon="pi pi-pencil" 
        iconPos="left" 
        class="tw-h-11 tw-text-sm tw-mr-3 tw-bg-transparent tw-text-black tw-hover:tw-bg-neutral-300 tw-border-gray-300" 
        @click="$router.push({
          name: 'edit-salon-service',
          params: {id: id},
        })"
      />
    </div>
    <h5 class="tw-text-xl inter-semibold tw-text-black">
      Detail Layanan Salon
    </h5>
  </div>

  <div class="tw-p-6 md:tw-p-10">
    <div class="card tw-mb-10">
      <div class="grid">
        <div class="col-12 sm:col-5 md:col-12 lg:col-4">
          <h3 class="mb-3 tw-text-lg inter-semibold">Foto</h3>
          <div class="grid">
            <div class="col-12 text-center">
              <div class="photo" :style="{'backgroundImage': `url(${data.image})`}"></div>
            </div>
          </div>  
        </div>

        <div class="col-12 sm:col-6 md:col-12 lg:col-7 sm:col-offset-1 md:col-offset-0 lg:col-offset-1">
          <div class="grid sm:tw-pt-10">
            <div class="col-12 lg:col-4">
              <div class="tw-mb-6" v-if="isAdminPriti()">
                <h6 class="opacity-60 inter-regular tw-text-sm tw-mb-2">
                  Nama Salon
                </h6>
                <h6 class="tw-text-md inter-medium">
                  {{ data.salon_name }}
                </h6>
              </div>

              <div class="tw-mb-6">
                <h6 class="opacity-60 inter-regular tw-text-sm tw-mb-2">
                  Nama Layanan
                </h6>
                <h6 class="tw-text-md inter-medium">
                  {{ data.name }}
                </h6>
              </div>

              <div class="">
                <h6 class="opacity-60 inter-regular tw-text-sm tw-mb-2">
                  Status
                </h6>
                <h6 class="tw-text-md inter-medium tw-flex tw-items-center">
                  <InputSwitch
                    v-model="data.is_active"
                    @change="activeInactiveService(data)"
                  />
                  <span class="ml-2">Active</span>
                </h6>
              </div>
            </div>

            <div class="col-12 lg:col-6">
              <div class="tw-mb-6">
                <h6 class="opacity-60 inter-regular tw-text-sm tw-mb-2">
                  Kategori Layanan
                </h6>
                <h6 class="tw-text-md inter-medium">
                  {{ data.category_name }}
                </h6>
              </div>

              <div class="tw-mb-6">
                <h6 class="opacity-60 inter-regular tw-text-sm tw-mb-2">
                  Estimasi Waktu
                </h6>
                <h6 class="tw-text-md inter-medium">
                  {{ data.estimated_time }} Menit
                </h6>
              </div>
              
              <div class="tw-mb-6">
                <h6 class="opacity-60 inter-regular tw-text-sm tw-mb-2">
                  Harga
                </h6>
                <h6 class="tw-text-md inter-medium">
                  {{ formatCurrency(data.price) }}
                </h6>
              </div>

              <div class="">
                <h6 class="opacity-60 inter-regular tw-text-sm tw-mb-2">
                  Deskripsi Layanan
                </h6>
                <h6 class="tw-text-md inter-medium">
                  {{ data.service_desc }}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAuthRole } from "@/composables/auth";

export default {
  name: '',
  props: ['id'],
  setup() {
    const { isAdminPriti } = useAuthRole()
    return { isAdminPriti }
  },
  data() {
    return {
      data: {},
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    getData() {
      this.$http
        .get(`${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/services/${this.id}`)
        .then(response => {
          this.data = response.data.data
          this.data.is_active = this.data.status == 1 ? true : false
        })
    },
    formatCurrency(data) {
      let value = parseInt(data)
      return value.toLocaleString('id-ID', {
        style: 'currency', 
        currency: 'IDR', 
        minimumFractionDigits: 0,
      })
    },
    activeInactiveService(data) {
      data.service_name = data.name
      data.status = data.is_active ? 1 : 0

      this.$http
        .put(`${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/services/${this.id}`, data)
        .then(response => {
          this.$toast.add({
            severity: 'success',
            summary: 'Success',
            detail: response.data.message,
            life: 3000,
          });
        })
        .catch((error) => {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: error.response.data.message,
            life: 3000,
          });
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.photo {
  width: 100%;
  height: 240px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
}
</style>